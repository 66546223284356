@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  overscroll-behavior-y: none;
  font-family: "monospace";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  background: linear-gradient(#0c071f, #190f40);
}

a {
  pointer-events: all;
  color: #ddd;
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  font-family: "Inter", sans-serif;
  color: white;
  text-shadow: 0 0 20px #8993ff;
  z-index: 1;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar a {
  margin-right: 20px;
}

.navbar-icon {
  width: 30px;
  height: 30px;
  fill: white;
  transition: all 0.2s ease-in-out;
}

.navbar-icon:hover {
  fill: #8993ff;
  transform: translateY(-2px);
}

.navbar h1 {
  position: fixed;
  left: 0;
  margin-left: 20px;
}

.navbar h1:hover {
  cursor: pointer;
  animation: neon 0.5s ease-in-out infinite alternate;
}

.box-text {
  padding: 1rem;
  display: block;
  -webkit-overflow-scrolling: touch;
}


@keyframes neon {
  0% {
    text-shadow: 0 0 20px #8993ff;
  }
  50% {
    text-shadow: 0 0 10px #8993ff;
  }
  100% {
    text-shadow: 0 0 20px #8993ff;
  }
}

.nav-menu {
  position: fixed;
  font-family: "Inter", sans-serif;
  top: 0%;
  left: 40%;
  font-size: 1.5rem;
  width: 100%;
  height: 20px;
  color: white;
  text-shadow: 0 0 20px #8993ff;
  z-index: 1;
  display: flex;
}

.nav-menu-item {
  margin-top: 20px;
  margin-right: 20px;
}

.nav-menu-item a {
  transition: 0.1s ease-in-out;
}

.nav-menu-item a:hover {
  display: inline-block;
  cursor: pointer;
  scale: 1.1;
  border-bottom: #8993ff solid 2px;
  color: white;
  animation: neon 0.5s ease-in-out infinite alternate;
  transform: translateX(4px);
}

.footer {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 60px;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  text-shadow: 0 0 20px #8993ff;
}

.footer-text {
  display: block;
  margin-right: 20px;
}

#main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.mobile-card {
  transition: all 0.2s;
  position: absolute;
  top: 200px;
  height: 350px;
  width: 350px;
  z-index: 1;
  padding: 20px;
  font-family: "monospace";
  font-weight: bold;
  font-size: 1.1rem;
  overflow: scroll;
  background: rgba(91, 89, 89, 0.2);
  border-radius: 10px;
  overflow-x: hidden;
  margin-left: 15px;
  text-shadow: 0 0 20px #8993ff,
}

.box-title {
  display: inline-block;
}

.type-effect {
  overflow: hidden;
  border-right: 3px solid #ddd;
  white-space: nowrap;
  animation: 
    type-effect 0.8s steps(15, end) forwards,
    blink 0.7s infinite;
}

@keyframes type-effect {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes blink {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: #ddd;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f100;
}
::-webkit-scrollbar-thumb {
  background: #b9bcdd;
}
::-webkit-scrollbar-thumb:hover {
  background: #8993ff;
}

@media screen and (max-width: 1850px) {
  .nav-menu {
    left: 40%;
    top: 0%;
    width: 100%;
    height: 20px;
    display: flex;
    font-size: 1.2rem;
  }

  .nav-menu-item {
    margin-right: 30px;
  }
}

@media screen and (max-width: 850px) {
  .nav-menu {
    left: 30%;
    top: 10%;
    width: 100%;
    height: 20px;
    font-size: 1.2rem;
    display: flex;
  }

  .nav-menu-item {
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .navbar h1 {
    font-size: 1.5rem;
  }
  .navbar-icon {
    width: 20px;
    height: 20px;
  }

  .navbar a {
    margin-right: 10px;
  }

  .nav-menu {
    top: 10%;
    left: 10%;
    font-size: 1.2rem;
  }

  .footer {
    font-size: 0.8rem;
  }
}

